<template>

    <span>
    <v-layout mb-4>
      <v-flex xs10 >
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Fretes - Inclusão</h2>
      </v-flex>
      <v-flex xs2 text-xs-right>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'store-shippings'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
        <form-store-shipping :btnLabel="btnLabel"></form-store-shipping>
    </span>
        
</template>

<script>
import FormStoreShippingComponent from "./partial/FormStoreShippingComponent";

export default {
  name: "AddStoreShippingComponent",
  data () {
    return {
        btnLabel: 'Incluir'
    }
  },
  methods: {

  },
  components: {
    formStoreShipping: FormStoreShippingComponent,
  }
};
</script>

<style scoped>

</style>
